import React, { useState, useEffect } from 'react';
import Container from '../Container/Container';
import SvgComponent from '../../assets/svg/SvgComponent';
import styles from './NewsletterConfirmation.module.scss';
import { confirmEmail } from '../../dataManager/newsletterDataManager';
import Button from '../Button/Button';

const NewsletterConfirmation = ({ search }) => {
    const [componentState, setComponentState] = useState('loading');

    function getTokenFromUrlParams(params) {
        var urlParams = new URLSearchParams(params);
        return urlParams.get('token');
    }

    useEffect(() => {
        const token = getTokenFromUrlParams(search);

        const checkEmail = async () => {
            setComponentState('loading');
            const response = await confirmEmail(token);
            if (response.status === 200) {
                setComponentState('confirmed');
            } else {
                setComponentState('failed');
            }
        };
        if (search) {
            checkEmail();
        }
    }, [search]);

    return (
        <Container className={styles.container}>
            {componentState === 'loading' && <Loading />}
            {componentState === 'confirmed' && <EmailCheckSucceed />}
            {componentState === 'failed' && <EmailCheckFailed />}
        </Container>
    );
};

const Loading = () => {
    return <SvgComponent name="loader" />;
};

const EmailCheckSucceed = () => {
    return (
        <React.Fragment>
            <SvgComponent
                name="newsletterEmailCheckSucceed"
                className={styles.svg}
            />
            <p>Parfait, ton mail est confirmé, tu es bien inscrit !</p>
            <Button.Component
                type={Button.Type.ROUTER_LINK_AS_BUTTON}
                to="/"
                className={styles.btn}
            >
                Retourner à l'accueil
            </Button.Component>
        </React.Fragment>
    );
};

const EmailCheckFailed = () => {
    return (
        <React.Fragment>
            <SvgComponent
                name="newsletterEmailCheckFailed"
                className={styles.svg}
            />
            <p>Ce lien ne semble pas valide !</p>
            <Button.Component
                type={Button.Type.ROUTER_LINK_AS_BUTTON}
                to="/"
                className={styles.btn}
            >
                Retourner à l'accueil
            </Button.Component>
        </React.Fragment>
    );
};

export default NewsletterConfirmation;
