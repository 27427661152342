import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/Seo';
import NewsletterConfirmation from '../components/NewsletterConfirmation/NewsletterConfirmation';

const NewsletterConfirmationPage = props => (
    <Layout>
        <SEO title="Confirmation d'inscription à la newsletter" />
        <NewsletterConfirmation search={props.location.search} />
    </Layout>
);

export default NewsletterConfirmationPage;
